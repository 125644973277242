import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

Vue.use(Vuex);

const state = {
  auth: {
    id: 0,
    email: null,
    token: localStorage.getItem('token')
  },
  fetchingProcesses: 0,
  status: 0,
  notification: {
    id: '',
    text: '',
    severity: '',
  },
  problem: {},
  domains: [],
  domainUsers: {},
  aliasesByDomain: {},
  aliasesByUser: {},
  usersById: {},
  domainsById: {}
};

let store = new Vuex.Store({
  name: 'store',
  state,
  actions,
  getters,
  mutations,
  strict: process.env.NODE_ENV !== 'production'
});

export default store;

<template>
  <v-form ref="form"
          v-model="valid"
          lazy-validation>
    <v-row align="center">

      <v-col class="align-content-center">
        <v-text-field
            :rules="[rules.required, rules.emailName]"
            type="text"
            name="new-email"
            label="Email"
            v-model="name"
            :value="name"
            class="input-group--focused"
        ></v-text-field>
      </v-col>
      <v-col class="flex-shrink-1 flex-grow-0 pl-0">
        <div class="at-divider text-no-wrap">@{{domain.name}}</div>
      </v-col>

      <v-col class="flex-fill">
        <v-text-field
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            required
            :rules="[rules.min]"
            :type="showPassword ? 'text' : 'password'"
            name="new-password"
            label="Passwort"
            v-model="password"
            class="input-group--focused"
            @click:append="showPassword = !showPassword"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="start">
      <v-col cols="12" class="d-flex align-items-center">
        <v-btn
            v-on:click.prevent="doCreateAccount({
                  userId: getUserId(),
                  name,
                  password,
                  domainId: domain.id
            })"
            :disabled="!valid"
            class="primary "
            type="submit">Email-Account erstellen
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {emailNamePattern, pwPattern} from '@/store/constants';

export default {
  name: "CreateUserForm",
  props: {
    domain: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      valid: false,
      multiple: false,
      name: '',
      password: '',
      showPassword: false,
      rules: {
        required: value => !!value || 'Pflichtfeld.',
        emailName: value => emailNamePattern.test(value) || 'Name ungültig.',
        min: value => {
          return (
              pwPattern.test(value) ||
              "Min. 8 Zeichen mit Großbuchstaben, Ziffern und Sonderzeichen('\"|+()/\\=_#?!@$ %^&*-)"
          );
        }
      },
    };
  },

  methods: {
    ...mapActions(['createAccount']),
    ...mapGetters(['getEmail', 'getUserId', 'hasErrorNotification']),
    async doCreateAccount(createRequest) {
      this.valid = this.$refs.form.validate();
      if (this.valid) {
        await this.createAccount(createRequest);
        if (!this.hasErrorNotification()) {
          this.$refs.form.reset();
          this.$emit('created');
        }
      }
    }
  }
}
</script>

<style scoped>
  .at-divider {
    font-size: 25px;
    margin-top: 10px;
  }
</style>
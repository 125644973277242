import Login from '@/components/Login';
import Home from '@/components/Home';
import DomainDashboard from '@/components/DomainDashboard';
import UserDashboard from '@/components/UserDashboard';
import Settings from '@/components/Settings';
import Domains from '@/components/Domains';
import Aliases from '@/components/Aliases';

const routes = [
  {
    component: Home,
    name: 'home',
    path: '/',
    meta: {requiresAuth: true},
  },
  {
    component: Domains,
    name: 'domains',
    path: '/domains',
    meta: {requiresAuth: true},
  },
  {
    component: Aliases,
    name: 'aliases',
    path: '/aliases',
    meta: {requiresAuth: true},
  },
  {
    component: DomainDashboard,
    name: 'domain-dashboard',
    path: '/domain/:id/:name',
    props: props({
      id: Number,
      name: String
    }),
    meta: {requiresAuth: true},
  },
  {
    component: Settings,
    name: 'settings',
    path: '/settings/:id',
    props: props({
      id: Number
    }),
    meta: {requiresAuth: true},
  },
  {
    component: UserDashboard,
    name: 'user-dashboard',
    path: '/user/:id/:name',
    props: props({
      id: Number,
      name: String
    }),
    meta: {requiresAuth: true},
  },
  {
    component: Login,
    name: 'login',
    path: '/login',
    meta: {requiresAuth: false}
  }
];

function props(config) {
  return (route) => {
    const routeProps = {};
    const propertyNames = Object.getOwnPropertyNames(config);
    for (let i = 0; i < propertyNames.length; i++) {
      const name = propertyNames[i];
      const type = config[name];
      if (type === Number) {
        routeProps[name] = Number(route.params[name]);
      } else {
        routeProps[name] = route.params[name]
      }
    }
    return routeProps;
  }
}

export default routes;

<template>
  <v-container class="login">
    <h1 class="mb-12 font-weight-thin">Login</h1>
    <NotificationDisplay/>
    <v-form>
      <v-text-field
          dense
          :label="'Email'"
          name="user-email"
          outlined
          required
          v-model="email"
      ></v-text-field>
      <v-text-field
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          required
          :type="showPassword ? 'text' : 'password'"
          name="user-pw"
          label="Passwort"
          v-model="password"
          class="input-group--focused"
          @click:append="showPassword = !showPassword"
      ></v-text-field>
      <div>
        <v-btn
            v-on:click.prevent="login({ email, password })"
            class="primary"
            type="submit">Login
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import NotificationDisplay from '@/components/NotificationDisplay';

export default {
  name: 'Login',
  components: {NotificationDisplay},
  computed: {
    ...mapState(['auth'])
  },
  methods: {
    ...mapActions(['login']),
  },
  data() {
    return {
      email: '',
      password: '',
      showPassword: false,
      rules: {
        required: value => !!value || 'Pflichtfeld.'
      }
    };
  }
}
</script>

<style scoped>
.login {
  max-width: 600px;
}
</style>

<template>
  <div>
    <h2>Email Admin</h2>
    {{ getEmail() }}
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "LandingContainer",
  methods: {
    ...mapGetters(['getEmail'])
  }
}
</script>

<style scoped>

</style>
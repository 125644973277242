import Vue from 'vue'
import App from './App.vue'

import vuetify from './vuetify';
import '@mdi/font/css/materialdesignicons.css';

import router from './router';
import store from './store';


Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')

function mutate(obj, key, value) {
  obj[key] = value;
  return {...obj};
}

export default {
  incrementProcesses(state) {
    state.fetchingProcesses++;
  },
  decrementProcesses(state) {
    state.fetchingProcesses--;
  },
  setAuth(state, payload) {
    if (Object.getOwnPropertyNames(payload).length > 0 && payload.token) {
      localStorage.setItem('token', payload.token);
    } else {
      localStorage.removeItem('token');
    }
    state.auth = payload;
  },
  setStatus(state, status) {
    state.status = status;
  },
  setNotification(state, notification) {
    state.notification = notification;
  },
  setError(state, problem) {
    state.problem = problem;
  },
  setDomains(state, domains) {
    state.domains = domains;
  },
  setUsers(state, payload) {
    state.domainUsers = mutate(state.domainUsers, payload.id, payload.users);
  },
  setAliasesByDomain(state, payload) {
    state.aliasesByDomain = mutate(state.aliasesByDomain, payload.id, payload.aliases);
  },
  setAliasesByUser(state, payload) {
    state.aliasesByUser = mutate(state.aliasesByUser, payload.id, payload.aliases);
  },
  setUserById(state, {id, user}) {
    state.usersById = mutate(state.usersById, id, user);
  },
  setDomainById(state, {id, domain}) {
    state.domainsById = mutate(state.domainsById, id, domain);
  },
  deleteAliasesFromUser(state, { userId, aliasId }) {
    for (let i = 0; i < state.aliasesByUser[userId].length; i++) {
      if (state.aliasesByUser[userId][i].id === aliasId) {
        state.aliasesByUser = state.aliasesByUser[userId].splice(i, 1);
        return;
      }
    }
  }
};

import router from '../router'
import {SEVERITY_ERROR, SEVERITY_SUCCESS, SEVERITY_WARNING} from '@/store/constants';

const baseUrl = process.env.VUE_APP_BACKEND;

class Http {

  requestSettings(method, data) {
    return {
      method, // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.getAuth()
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: data ? JSON.stringify(data) : undefined
    };
  }

  getAuth() {
    let token = localStorage.getItem('token');
    return token ? 'Bearer ' + token : null;
  }

  // eslint-disable-next-line no-unused-vars
  async getData({url, commit}) {
    return this.sendData({ url, commit,
      requestSettings: this.requestSettings('GET')
    });
  }

  async deleteData({url, commit}) {
    return this.sendData({ url, commit,
      requestSettings: this.requestSettings('DELETE')
    });
  }

  async postData({url = '', data = {}, commit}) {
    return this.sendData({ url, commit,
      requestSettings: this.requestSettings('POST', data)
    });
  }

  async sendData({url, requestSettings, commit}) {
    try {
      commit('incrementProcesses');
      const response = await fetch(baseUrl + url, requestSettings);
      return await this.parseResponse(response);
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit('decrementProcesses');
    }
  }

  async parseResponse(response) {
    try {
      let body = await response.json();
      return {
        status: response.status,
        data: body,
        ok: response.ok
      };
    } catch (e) {
      if (response.ok) {
        response.bodyText && console.log(response.bodyText);
      }
      return {
        status: response.status
      };
    }
  }
}

const http = new Http();

export default {
  async getStatus({commit}) {
    try {
      let response = await http.getData({url: '/status', commit});
      commit('setStatus', response.status);
    } catch (response) {
      commit('setError', { problem: response });
    }
  },
  // eslint-disable-next-line no-unused-vars
  async login({ commit, dispatch }, payload) {

    try {

      let response = await http.postData({ url: '/login', data: payload, commit });
      if (response.ok) {
        commit('setAuth', response.data);

        await dispatch('loadDomains');
        await dispatch('loadAliasesByUser', response.data.id);
        await router.push({path: '/'});
      } else {
        dispatch('processProblem', {
          response,
          fallback: {
            text: 'Login fehlgeschlagen.',
            severity: SEVERITY_ERROR
          }
        });
      }
    } catch (response) {
      commit('setError', { problem: response });
    }
  },
  async logout({ commit }) {
    commit('setAuth', {});
    await router.push({ name: 'login' });
  },
  async createAccount({commit, dispatch}, emailData) {
    try {
      let response = await http.postData({url: '/users', data: emailData, commit});
      if (response.ok) {
        commit('setNotification', { id: 'USER', text: 'Email-Account erstellt.', severity: SEVERITY_SUCCESS });
      } else {
        dispatch('processProblem', {
          response,
          fallback: {
            text: 'Email-Account konnte nicht angelegt werden.',
            severity: SEVERITY_ERROR
          }
        });
      }

    } catch (response) {
      commit('setError', { problem: response.data });
    }
  },
  async loadDomains({ commit, dispatch }) {
    try {
      let response = await http.getData({url: '/domains', commit});
      if (!response.ok) {
        dispatch('processProblem', {
          response,
          fallback: {
            text: 'Daten konnten nicht geladen werden.',
            severity: SEVERITY_ERROR
          }
        });
        return;
      }
      commit('setDomains', response.data);

    } catch (response) {
      commit('setError', { problem: response.data });
    }
  },
  async loadAccounts({ commit, dispatch }, domain) {

    await dispatch('loadAliasesByDomain', domain);

    try {
      let response = await http.getData({url: `/domains/${domain.id}/users`, commit});
      if (!response.ok) {
        dispatch('processProblem', {
          response,
          fallback: {
            text: 'Daten konnten nicht geladen werden.',
            severity: SEVERITY_ERROR
          }
        });
        return;
      }
      commit('setUsers', { id: domain.id, users: response.data })

    } catch (response) {
      commit('setError', { problem: response });
    }
  },
  async loadAliasesByDomain({ commit, dispatch }, domain) {
    try {
      let response = await http.getData({url: `/aliases/domain/${domain.id}`, commit});
      if (!response.ok) {
        dispatch('processProblem', {
          response,
          fallback: {
            text: 'Daten konnten nicht geladen werden.',
            severity: SEVERITY_ERROR
          }
        });
      } else {
        commit('setAliasesByDomain', { id: domain.id, aliases: response.data })
      }

    } catch (response) {
      commit('setError', { problem: response });
    }
  },
  async loadAliasesByUser({ commit, dispatch }, userId) {
    try {
      let response = await http.getData({url: `/aliases/user/${userId}`, commit});
      if (!response.ok) {
        dispatch('processProblem', {
          response,
          fallback: {
            text: 'Daten konnten nicht geladen werden.',
            severity: SEVERITY_ERROR
          }
        });
      } else {
        commit('setAliasesByUser', { id: userId, aliases: response.data });
      }

    } catch (response) {
      commit('setError', { problem: response });
    }
  },
  async deleteUser({commit, dispatch}, { userId, domain }) {
    try {
      const response = await http.deleteData({url: `/users/${userId}`, commit});
      if (response.ok) {
        await router.push({ name: 'domain-dashboard', params: domain })
        commit('setNotification', { id: 'USER', text: 'Benutzer gelöscht.', severity: SEVERITY_SUCCESS });
      } else {
        dispatch('processProblem', {
          response,
          fallback: {
            text: 'Benutzer konnte nicht gelöscht werden.',
            severity: SEVERITY_ERROR
          }
        });
      }
    } catch (response) {
      commit('setError', { problem: response });
    }
  },
  async deleteAlias({ commit, dispatch }, { userId, aliasId }) {
    try {
      const response = await http.deleteData({url: `/aliases/id/${aliasId}`, commit});
      await dispatch('loadAliasesByUser', userId);
      if (response.ok) {
        commit('setNotification', {id: 'ALIAS', text: 'Weiterleitung entfernt.', severity: SEVERITY_SUCCESS});
      } else if (response.status === 401) {
        await dispatch('logout');
      } else {
        dispatch('processProblem', {
          response,
          fallback: {
            text: 'Daten konnten nicht geladen werden.',
            severity: SEVERITY_ERROR
          }
        });
      }
    } catch (response) {
      commit('setError', { problem: response });
    }
  },
  async fetchUser({commit, dispatch}, userId) {
    try {
      const response = await http.getData({url: `/users/${userId}`, commit});
      if (response.ok) {
        await commit('setUserById', {id: userId, user: response.data});
        await dispatch('fetchDomain', response.data.domainId);
      } else if (response.status === 401) {
        await dispatch('logout');
      } else {
        dispatch('processProblem', {
          response,
          fallback: {
            text: 'Daten konnten nicht geladen werden.',
            severity: SEVERITY_ERROR
          }
        });
      }
    } catch (response) {
      commit('setError', { problem: response });
    }
  },
  async fetchDomain({commit, dispatch}, domainId) {
    try {
      const response = await http.getData({url: `/domains/${domainId}`, commit});
      if (response.ok) {
        commit('setDomainById', { id: domainId, domain: response.data });
      } else {
        dispatch('processProblem', {
          response,
          fallback: {
            text: 'Daten konnten nicht geladen werden.',
            severity: SEVERITY_ERROR
          }
        });
      }
    } catch (response) {
      commit('setError', { problem: response });
    }
  },
  async changePassword({commit, dispatch}, {userId, oldPassword, newPassword}) {
    try {
      const response = await http.postData({url: `/users/${userId}/password`, commit, data: { oldPassword, newPassword }});
      if (response.ok) {
        commit('setNotification', { text: 'Passwort geändert', severity: SEVERITY_SUCCESS })
      } else {
        dispatch('processProblem', {
          response,
          fallback: {
            text: 'Passwort ändern fehlgeschlagen',
            severity: SEVERITY_ERROR
          }
        });
      }
    } catch (response) {
      commit('setError', { problem: response });
    }
  },
  dismissNotification({commit}) {
    commit('setNotification', {});
  },

  async createAlias({commit, dispatch}, { userId, domainId, source, destination }) {
    try {
      const response = await http.postData({url: `/aliases`, commit, data: { domainId, source, destination }});
      if (response.ok) {
        await dispatch('loadAliasesByUser', userId);
        commit('setNotification', { text: 'Weiterleitung erstellt', severity: SEVERITY_SUCCESS })
      } else {
        dispatch('processProblem', {
          response,
          fallback: {
            text: 'Weiterleitung konnte nicht erstellt werden.',
            severity: SEVERITY_ERROR
          }
        });
      }
    } catch (response) {
      commit('setError', { problem: response });
    }
  },

  async processProblem({commit, dispatch}, { response, fallback }) {
    if (response.status >= 500) {
      commit('setNotification', fallback);
      return;
    }

    const problem = response.data;
    switch (problem.id) {
      case 'USER-1':
        commit('setNotification', {
          id: problem.id,
          text: 'Login fehlgeschlagen. Falsches Passwort oder falsche Email?',
          severity: SEVERITY_ERROR
        });
        break;
      case 'USER-2':
        commit('setNotification', {
          id: problem.id,
          text: 'Benutzername ist schon vergeben.',
          severity: SEVERITY_ERROR
        });
        break;
      case 'USER-3':
        commit('setNotification', {
          id: problem.id,
          text: 'Benutzer unbekannt.',
          severity: SEVERITY_ERROR
        });
        break;
      case 'USER-4':
        commit('setNotification', {
          id: problem.id,
          text: 'Passwort nicht sicher genung.',
          severity: SEVERITY_ERROR
        });
        break;
      case 'USER-5':
        commit('setNotification', {
          id: problem.id,
          text: 'Benutzername ungültig.',
          severity: SEVERITY_ERROR
        });
        break;
      case 'USER-6':
        commit('setNotification', {
          id: problem.id,
          text: 'Keine Berechtigung',
          severity: SEVERITY_ERROR
        });
        break;
      case 'USER-7':
        commit('setNotification', {
          id: problem.id,
          text: 'Falsches Passwort',
          severity: SEVERITY_ERROR
        });
        break;
      case 'ALIAS-1':
        commit('setNotification', {
          id: problem.id,
          text: 'Weiterleitung existiert schon.',
          severity: SEVERITY_WARNING
        });
        break;
      case 'ALIAS-2':
        commit('setNotification', {
          id: problem.id,
          text: 'Weiterleitungsziel ist ungültig.',
          severity: SEVERITY_ERROR
        });
        break;
      case 'ALIAS-3':
        commit('setNotification', {
          id: problem.id,
          text: 'Weiterleitung konnte nicht erstellt werden.',
          severity: SEVERITY_ERROR
        });
        break;
      case 'ALIAS-4':
        commit('setNotification', {
          id: problem.id,
          text: 'Weiterleitung nicht gefunden.',
          severity: SEVERITY_ERROR
        });
        break;
      case 'ALIAS-5':
        commit('setNotification', {
          id: problem.id,
          text: 'Weiterleitung ungültig.',
          severity: SEVERITY_ERROR
        });
        break;
      case 'ALIAS-6':
        commit('setNotification', {
          id: problem.id,
          text: 'Keine Berechtigung.',
          severity: SEVERITY_ERROR
        });
        break;
      default:
        if (fallback) {
          fallback.id = problem.id;
          commit('setNotification', fallback);
        }
        break;
    }
    if (response.status === 401 && router.currentRoute.name !== 'login') {
      await dispatch('logout');
    }
  }
};

<template>
  <v-container>
    <v-toolbar
        flat
    >
      <v-toolbar-title>
        <h2 class="font-weight-thin">
          Einstellungen von
          <router-link :to="{ name: 'domain-dashboard', params: domain }">
            {{ email }}
          </router-link>
        </h2>
      </v-toolbar-title>
      <template v-slot:extension>
        <v-tabs
            v-model="tabModel"
            slider-color="black"
        >
          <v-tab
              v-for="item in tabItems"
              :key="item.name"
              :href="`#tab-${item.name}`"
          >
            <v-icon left>
              {{ item.icon }}
            </v-icon>
            {{ item.text }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tabModel" class="pt-8">
      <v-tab-item :value="`tab-${tabItems[0].name}`">
        <h2>Neue Weiterleitung</h2>
        <NotificationDisplay filter="ALIAS" />
        <CreateAliasForm :id="id" :defaultDomain="domain" :defaultDestination="email" />
      </v-tab-item>

      <v-tab-item :value="`tab-${tabItems[1].name}`">
        <h2>Weiterleitungen</h2>
        <p>Hier siehst du alle Weiterleitungen, die auf diesen Account zeigen.</p>
        <NotificationDisplay filter="ALIAS" />
        <AliasesDisplay :id="id"/>
      </v-tab-item>

      <v-tab-item :value="`tab-${tabItems[2].name}`">
        <h2>Passwort ändern</h2>
        <NotificationDisplay filter="USER" />
        <ChangePasswordForm :user="user" :is-admin="isAdmin"/>
      </v-tab-item>

      <v-tab-item :value="`tab-${tabItems[3].name}`">
        <v-card color="red lighten-5 pa-6">
          <h2>Konto Löschen</h2>
          <NotificationDisplay filter="USER" />
          <v-form v-model="deleteValid">
            <v-text-field
                :rules="[rules.same]"
                type="text"
                name="emailAgain"
                v-model="emailAgain"
                :value="emailAgain"
                class="input-group--focused"
                label="Email Adresse eingeben um das Konto zu löschen.">

            </v-text-field>
            <v-btn color="error" @click="deleteValid && doDeleteUser()">Löschen</v-btn>
          </v-form>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import AliasesDisplay from '@/components/AliasesDisplay';
import NotificationDisplay from '@/components/NotificationDisplay';
import {mapActions, mapGetters, mapState} from 'vuex';
import CreateAliasForm from '@/components/CreateAliasForm';
import ChangePasswordForm from '@/components/ChangePasswordForm';

export default {
  name: "Dashboard",
  components: {ChangePasswordForm, CreateAliasForm, AliasesDisplay, NotificationDisplay},
  props: {
    id: [Number],
    name: [String]
  },
  data() {
    return {
      tab: null,
      tabModel: 'tabs',
      tabItems: [
        { name: 'new', text: 'Neue Weiterleitung', icon: 'mdi-at' },
        { name: 'aliases', text: 'Weiterleitungen', icon: 'mdi-arrow-decision' },
        { name: 'password', text: 'Passwort ändern', icon: 'mdi-security' },
        { name: 'delete', text: 'Account Löschen', icon: 'mdi-delete' },
      ],
      user: null,
      deleteValid: false,
      email: '',
      emailAgain: '',
      domain: {},
      rules: {
        same: value => !!value && value === this.email || 'Stimmt noch nicht überein...',
      },
    }
  },
  computed: {
    ...mapState(['usersById', 'domainsById']),
    isAdmin() {
      return this.user && this.user.id !== this.getUserId();
    }
  },
  methods: {
    ...mapGetters(['getUserId']),
    ...mapActions(['fetchUser', 'deleteUser']),
    doDeleteUser() {
      this.deleteUser({ userId: this.id, domain: this.domain });
    },
    async fetchData() {
      await this.fetchUser(this.id);

      if (!this.usersById || !this.domainsById) { return; }
      this.user = this.usersById[this.id];
      if (!this.user) return;

      const domainId = this.user.domainId;
      this.domain = domainId && this.domainsById[domainId];
      this.email = this.domain && `${this.name}@${this.domain.name}`;
    }
  },
  async mounted() {
    await this.fetchData();
  },
  async created () {
    await this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  }
}
</script>

<style scoped>

</style>
<template>
  <v-data-table
      :headers="aliasesHeaders"
      :search="aliasSearch"
      :items="aliases"
      group-by="domain.name"
      :items-per-page="25"
      class="elevation-1"
  >
    <template v-slot:top>
      <v-text-field
          v-model="aliasSearch"
          label="Suche"
          class="mx-4"
          append-icon="mdi-magnify"
          single-line
          hide-details
      ></v-text-field>
    </template>
    <template v-slot:item.source="{item}">
      {{item.source}}@{{item.domain.name}}
    </template>
    <template v-slot:item.action="{item}">
      <v-btn @click="doDeleteAlias({ aliasId: item.id, userId: id})">Löschen</v-btn>
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
  name: "AliasesDisplay",
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState(['aliasesByUser'])
  },
  data() {
    return {
      aliases: [],
      aliasSearch: '',
      aliasesHeaders: [
        { text: 'ID', value: 'id', filterable: false },
        { text: 'Von', value: 'source', filterable: true },
        { text: 'An', value: 'destination', filterable: false },
        { text: 'Action', value: 'action', filterable: false}
      ]
    }
  },

  methods: {
    ...mapActions(['loadAliasesByUser', 'deleteAlias']),

    async doDeleteAlias(payload) {
      await this.deleteAlias(payload);
    },
    async fetchData() {
      await this.loadAliasesByUser(this.id);
    }
  },
  async mounted() {
    await this.fetchData();
  },
  async created () {
    await this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData',
    aliasesByUser() {
      this.aliases = this.aliasesByUser[this.id];
    }
  }
}
</script>

<style scoped>

</style>
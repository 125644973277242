<template>
  <v-container>
    <v-toolbar
        flat
    >
      <v-toolbar-title><h2 class="font-weight-thin">{{ name }} Domain Dashboard </h2></v-toolbar-title>
      <template v-slot:extension>
        <v-tabs
            v-model="tabModel"
            slider-color="black"
        >
          <v-tab
              v-for="item in tabItems"
              :key="item.name"
              :href="`#tab-${item.name}`"
          >
            <v-icon left>
              {{ item.icon }}
            </v-icon>
            {{ item.text }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-tabs-items v-model="tabModel" class="pt-8">
      <v-tab-item :value="`tab-${tabItems[0].name}`">
        <v-data-table
            :headers="domainHeaders"
            :items="domainUsers[id]"
            :search="domainSearch"
            :items-per-page="50"
            class="mb-12"
        >
          <template v-slot:top>
            <v-text-field
                v-model="domainSearch"
                label="Suche"
                class="mx-4"
                append-icon="mdi-magnify"
                single-line
                hide-details
            ></v-text-field>
          </template>
          <template v-slot:item.name="{item}">
            <router-link :to="{ name: 'user-dashboard', params: { id: item.id, name: item.name }}">
              {{item.name}}
            </router-link>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item :value="`tab-${tabItems[1].name}`">
        <h2>Weiterleitungen von @{{ name }} an ...</h2>
        <p>Um eine neue Weiterleitung einzurichten bitte oben in der Liste einen Account auswählen.</p>
        <p>Die Tabelle zeigt alle Weiterleitungen, die von dieser Domain auf andere Accounts zeigen.</p>
        <NotificationDisplay filter="ALIAS" />

        <v-data-table
            :headers="aliasesHeaders"
            :search="aliasSearch"
            :items="aliasesByDomain[id]"
            group-by="domain.name"
            :items-per-page="50"
        >
          <template v-slot:top>
            <v-text-field
                v-model="aliasSearch"
                label="Suche"
                class="mx-4"
                append-icon="mdi-magnify"
                single-line
                hide-details
            ></v-text-field>
          </template>
          <template v-slot:item.source="{item}">
            {{item.source}}@{{item.domain.name}}
          </template>
          <template v-slot:item.action="{item}">
            <v-btn @click="doDeleteAlias({ aliasId: item.id, userId: getUserId})">Löschen</v-btn>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item :value="`tab-${tabItems[2].name}`">
        <div class="mb-12">
          <h2>Erstelle einen neuen Email-Account</h2>
          <NotificationDisplay filter="USER" />
          <CreateUserForm :domain="{id, name}" v-on:created="fetchData"/>
        </div>
      </v-tab-item>
    </v-tabs-items>

  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import NotificationDisplay from '@/components/NotificationDisplay';
import CreateUserForm from '@/components/CreateUserForm';

export default {
  name: "DomainDashboard",
  components: {CreateUserForm, NotificationDisplay},
  props: {
    id: [Number],
    name: [String]
  },
  data() {
    return {
      tab: null,
      tabModel: 'tabs',
      tabItems: [
          { name: 'accounts', text: 'Accounts', icon: 'mdi-at' },
          { name: 'aliases', text: 'Weiterleitungen', icon: 'mdi-arrow-decision' },
          { name: 'new', text: 'Neu', icon: 'mdi-account' },
      ],
      domainSearch: '',
      domainHeaders: [
        {text: 'ID', value: 'id', sortable: true},
        {text: 'Account', value: 'name', sortable: true},
      ],
      aliasSearch: '',
      aliasesHeaders: [
        { text: 'ID', value: 'id', filterable: false },
        { text: 'Von', value: 'source', filterable: true },
        { text: 'An', value: 'destination', filterable: false },
        { text: 'Action', value: 'action', filterable: false}
      ],
    }
  },
  computed: {
    ...mapState(['domainUsers', 'aliasesByDomain']),
    ...mapGetters(['getAliasesByDomain', 'getUserId']),
    domain() {
      return {
        id: this.id,
        name: this.name
      }
    }
  },

  methods: {
    ...mapActions(['loadAccounts', 'loadAliasesByDomain', 'deleteAlias']),
    async doDeleteAlias(payload) {
      await this.deleteAlias(payload);
      await this.loadAliasesByDomain(this.domain);
    },
    async fetchData() {
      await this.loadAccounts(this.domain);
    }
  },

  async created () {
    // fetch the data when the view is created and the data is
    // already being observed
    await this.fetchData();
  },

  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  }
}
</script>

<style scoped>

</style>
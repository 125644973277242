<template>
  <v-alert
      :value="hasNotification"
      dense
      :type="notification.severity || 'error'"
      @click="dismissNotification()"
  >
    {{notification.text}}
  </v-alert>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
  name: "NotificationDisplay",
  props: {
    filter: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      hasNotification: false
    };
  },
  computed: {
    ...mapState(['notification']),
  },
  methods: {
    ...mapActions(['dismissNotification']),
  },
  watch: {
    notification() {
      if (!this.notification || !this.notification.text) {
        this.hasNotification = false;
      } else {
        this.hasNotification = !this.filter || (this.notification.id && this.notification.id.startsWith(this.filter));
      }
    }
  }
}
</script>

<style scoped>
</style>
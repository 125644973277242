import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const vuetifyOpts = {
  icons: {
    iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    themes: {
      light: {
        background: '#8d8d8d',
        primary: '#34531f',
        secondary: '#868686',
        accent: '#0071ff',
        info: '#1976d2',
        warning: '#ffa000',
        error: '#d32f2f',
        success: '#388e3c'
      },
      dark: {
        background: '#18252f',
        secondary: '#141b4d'
      }
    }
  }
};

export default new Vuetify(vuetifyOpts);

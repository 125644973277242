<template>
  <v-container>
    <h2>Neue Weiterleitung</h2>
    <NotificationDisplay/>
    <CreateAliasForm v-if="domain" :id="getUserId()" :defaultDomain="domain" :defaultDestination="email" />

    <h2>Meine Weiterleitungen</h2>
    <p>Hier siehst du alle Weiterleitungen, die auf deinen Account zeigen.</p>
    <AliasesDisplay :id="getUserId()"/>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import NotificationDisplay from '@/components/NotificationDisplay';
import AliasesDisplay from '@/components/AliasesDisplay';
import CreateAliasForm from '@/components/CreateAliasForm';


export default {
  name: "Aliases",
  components: {CreateAliasForm, AliasesDisplay, NotificationDisplay},

  data() {
    return {
      email: '',
      domain: null,
    }
  },
  computed: {
    ...mapState(['usersById', 'domainsById'])
  },
  methods: {
    ...mapGetters(['getUserId']),
    ...mapActions(['fetchUser']),
    async fetchData() {

      const userId = this.getUserId();
      await this.fetchUser(userId);

      if (!this.usersById || !this.domainsById) {
        return;
      }
      let user = this.usersById[userId];
      if (!user) return;

      const domainId = user.domainId;
      this.domain = domainId && this.domainsById[domainId];
      this.email = this.domain && `${user.name}@${this.domain.name}`;
    }
  },
  async mounted() {
    await this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  }
}
</script>

<style scoped>
</style>
<template>
  <v-container v-if="domains">

    <h1 class="mb-12 font-weight-thin">Meine Domains</h1>
    <v-data-table
        :headers="domainHeaders"
        :items="domains"
        :search="domainSearch"
        :items-per-page="25"
        sort-by="name"
        class="mb-10"
    >
      <template v-slot:top>
        <v-text-field
            v-model="domainSearch"
            label="Suche"
            class="mx-4"
            append-icon="mdi-magnify"
            single-line
            hide-details
        ></v-text-field>
      </template>
      <template v-slot:item.name="{item}">
        <router-link :to="{ name: 'domain-dashboard', params: { id: item.id, name: item.name }}">
          {{item.name}}
        </router-link>
      </template>
    </v-data-table>


  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';

export default {
  name: "Domains",
  computed: {
    ...mapState(['domains']),
  },
  data() {
    return {
      domainSearch: '',
      domainHeaders: [
        { text: 'ID', value: 'id', sortable: true },
        { text: 'Domain', value: 'name', sortable: true },
      ],
    };
  },

  methods: {
    ...mapActions(['loadDomains']),
    ...mapGetters(['getUserId']),

    async fetchData() {
      await this.loadDomains();
    }
  },

  async created () {
    // fetch the data when the view is created and the data is
    // already being observed
    await this.fetchData();
  },

  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  }
}
</script>

<style scoped>

</style>
<template>
  <v-form>
    <v-row align="center">

      <v-col class="align-content-center">
        <v-text-field
            :rules="[rules.required, rules.emailName]"
            type="text"
            name="email"
            label="Weiterleitung"
            v-model="alias"
            :value="alias"
            class="input-group--focused"
        ></v-text-field>
      </v-col>
      <v-col class="flex-shrink-1 flex-grow-0 px-0">
        <div class="at-divider">@<span v-if="!domainItems.length">{{ domainValue }}</span></div>
      </v-col>
      <v-col>
        <v-autocomplete v-if="domainItems.length"
            v-model="domainValue"
            :items="domainItems"
            :search-input.sync="domainSearch"
            dense
            class="mt-4 input-group--focused text-left"
            label="Domain"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row align="start">
      <v-col cols="12" class="d-flex">
        <v-btn
            v-on:click.prevent="createAlias({
                  userId: id,
                  source: alias,
                  domainId,
                  destination
            })"
            class="primary mt-1 mb-6"
            type="submit">Weiterleitung erstellen
        </v-btn>
        <p v-if="alias" class="text-left info-text pl-10 justify-start">
          Emails an <span class="font-weight-bold text-no-wrap">{{alias}}@{{domainValue}}</span>
          werden an <span class="font-weight-bold text-no-wrap">{{destination}}</span> weitergeleitet
        </p>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import {emailNamePattern} from '@/store/constants';

export default {
  name: "CreateAliasForm",
  props: {
    id: {
      type: Number,
      required: true
    },
    defaultDomain: [Object],
    defaultDestination: [String]
  },
  data() {
    return {
      alias: '',
      domainId: this.defaultDomain && this.defaultDomain.id || 0,
      domainValue: '',
      domainSearch: '',
      domainItems: [],
      rules: {
        required: value => !!value || 'Pflichtfeld.',
        emailName: value => emailNamePattern.test(value) || 'Weiterleitung ungültig.'
      },
    };
  },
  computed: {
    ...mapState(['domains', 'domainUsers']),
    destination() {
      return this.defaultDestination || this.getEmail();
    }
  },

  methods: {
    ...mapActions(['loadDomains', 'createAlias']),
    ...mapGetters(['getEmail', 'getUserId', 'getDomain']),

    async fetchData() {
      // for the domain select
      await this.loadDomains();

      this.domainValue = (this.defaultDomain && this.defaultDomain.name) || this.getDomain();
    }
  },

  async created () {
    // fetch the data when the view is created and the data is
    // already being observed
    await this.fetchData();
  },
  watch: {
    domainSearch (val) {
      this.domains.forEach(value => {
        if (value.name === val) {
          this.domainId = value.id;
        }
      })
    },
    // call again the method if the route changes
    '$route': 'fetchData',
    domains() {
      this.domainItems = [];
      this.domains.forEach(value => {
        this.domainItems.push(value.name);
      });
      this.domainValue = (this.defaultDomain && this.defaultDomain.name) || this.getDomain();
    },
    defaultDomain() {
      this.domainValue = (this.defaultDomain && this.defaultDomain.name) || this.getDomain();
    }
  }
}
</script>

<style scoped>
  .at-divider {
    font-size: 25px;
    margin-top: 10px;
  }
</style>
<template>
  <v-form ref="form"
          v-model="valid"
          lazy-validation>
    <v-text-field v-if="!isAdmin"
        :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required]"
        :type="showOldPassword ? 'text' : 'password'"
        name="input-10-2"
        label="Altes Passwort"
        v-model="oldPassword"
        class="input-group--focused"
        @click:append="showOldPassword = !showOldPassword"
    ></v-text-field>
    <v-text-field
        :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required, rules.min, rules.pwMatch]"
        :type="showNewPassword ? 'text' : 'password'"
        name="input-10-2"
        label="Neues Passwort"
        v-model="newPassword"
        class="input-group--focused"
        @click:append="showNewPassword = !showNewPassword"
    ></v-text-field>
    <div>
      <v-btn
          v-on:click.prevent="doChangePassword"
          class="primary mt-6"
          :disabled="!valid"
          type="submit">Änderung speichern
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {pwPattern} from '@/store/constants';

export default {
  name: "ChangePasswordForm",
  props: ['user', 'isAdmin'],
  data() {
    return {
      valid: false,
      oldPassword: '',
      newPassword: '',
      showOldPassword: false,
      showNewPassword: false,
      rules: {
        required: value => !!value || 'Pflichtfeld.',
        min: value => {
          return (
              pwPattern.test(value) ||
              "Min. 8 Zeichen mit Großbuchstaben, Ziffern und Sonderzeichen('\"|+()/\\=_#?!@$ %^&*-)"
          );
        },
        pwMatch: v => (!!v && v) !== this.oldPassword || this.isAdmin || `Die Passwörter sind gleich`,
      }
    };
  },
  methods: {
    ...mapActions(['changePassword']),
    ...mapGetters(['hasErrorNotification']),
    async doChangePassword() {
      if (this.$refs.form.validate()) {
        await this.changePassword({
          userId: this.user.id,
          oldPassword: this.oldPassword,
          newPassword: this.newPassword
        });
        if (!this.hasErrorNotification()) {
          this.$refs.form.reset();
        } else {
          this.$emit('changed');
        }
      }
    }
  },
}
</script>

<style scoped>

</style>
<template>
  <v-app light id="app" class="d-flex ">
    <v-navigation-drawer v-if="auth.token"
                         fixed
                         permanent
                         app>
      <v-list-item class="px-2" :to="{name:'home'}">
        {{ getEmail() }}
      </v-list-item>

      <v-list-item :to="{name: 'settings', params : { id: getUserId() }}">
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Einstellungen</v-list-item-title>
      </v-list-item>

      <v-list-item :to="{name: 'domains'}">
        <v-list-item-icon>
          <v-icon>mdi-view-list</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Meine Domains</v-list-item-title>
      </v-list-item>

      <v-list-item :to="{name: 'aliases'}">
        <v-list-item-icon>
          <v-icon>mdi-arrow-decision</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Meine Weiterleitungen</v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Abmelden</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-navigation-drawer>

    <v-main class="main">
      <v-container fluid class="d-flex ma-6 align-content-center justify-center">
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer class="align-self-end" app>
      <p>
        API status: {{status}}
      </p>
    </v-footer>

    <v-overlay :value="isLoading">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>

import {mapActions, mapGetters, mapState} from 'vuex';

export default {
  name: 'App',
  data() {
    return {
      navItems: [
        { title: 'Domains', icon: 'mdi-domain', to: '/domains' }
      ],
    };
  },
  computed: {
    ...mapState(['auth', 'status', 'fetchingProcesses']),
    isLoading() {
      return this.fetchingProcesses > 0;
    }
  },
  methods: {
    ...mapActions(['login', 'logout', 'getStatus']),
    ...mapGetters(['getEmail', 'getUserId'])
  },
  async created () {
    // fetch the data when the view is created and the data is
    // already being observed
    try {
      await this.getStatus();
    } catch (e) {
      console.log(e);
    }
  },
  watch: {
    async status() {
      await this.getStatus();
    }
  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}
.main {
  max-width: 1200px;
}
</style>

<template>
  <v-container>
    <h1 class="mb-12 font-weight-thin">Einstellungen von {{email}}</h1>
    <NotificationDisplay />
    <h2>Passwort ändern</h2>
    <ChangePasswordForm :user="user"/>
  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import NotificationDisplay from '@/components/NotificationDisplay';
import ChangePasswordForm from '@/components/ChangePasswordForm';

export default {
  name: "Settings",
  components: {ChangePasswordForm, NotificationDisplay},
  props: ['id'],
  data() {
    return {
      user: null,
      email: '',
      oldPassword: '',
      newPassword: '',
      showOldPassword: false,
      showNewPassword: false,
      rules: {
        required: value => !!value || 'Pflichtfeld.',
        min: value => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
          return (
              pattern.test(value) ||
              "Min. 8 Zeichen mit Großbuchstaben, Ziffern und Sonderzeichen(!@#$%^&*)"
          );
        },
        pwMatch: v => (!!v && v) !== this.oldPassword || `Die Passwörter sind gleich`,
      }
    };
  },
  computed: {
    ...mapState(['usersById', 'domainsById', 'notification']),
  },
  methods: {
    ...mapActions(['fetchUser', 'changePassword', 'fetchDomain', 'dismissNotification']),
    getEmailById() {
      const user = this.usersById[this.id];
      if (!user) return '';

      const domain = this.domainsById[user.domainId];
      if (!domain) return '';

      return `${user.name}@${domain.name}`;
    },
    async fetchData() {
      if (this.id) {
        await this.fetchUser(this.id);
        this.user = this.usersById[this.id];
        const domainId = this.user.domainId;

        if (!this.domainsById[domainId]) {
          await this.fetchDomain(domainId);
        }

        this.email = this.getEmailById();
      }
    }
  },
  async mounted () {
    await this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  }
}
</script>

<style scoped>

</style>
import {SEVERITY_ERROR} from '@/store/constants';

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

const getters = {
  getAuth(state) {
    return state.auth;
  },

  getAliasesByDomain(state) {
    return state.aliasesByDomain
  },

  getEmail(state) {
    if (!state.auth.token) {
      return;
    }

    return parseJwt(state.auth.token).email;
  },

  getUserId(state) {
    if (!state.auth.token) {
      return;
    }

    return parseInt(parseJwt(state.auth.token).sub);
  },

  getDomain(state) {
    const email = state.auth.token && parseJwt(state.auth.token).email;
    return email && email.split('@')[1];
  },

  hasErrorNotification(state) {
    return state.notification && state.notification.severity === SEVERITY_ERROR;
  }

};

export default getters;
